import React from "react";

const SalesMobile = ({manager, isShow, region}) => {

    
    return (
      <div className={isShow ? "region__manager__mobile" : "region__manager contacts_iShow"}>
        
        <div className="region_bg">
        <h1 className="mt-30 pl-1rem">{region}</h1>
          <div className="region__position">{manager.position}</div>
          <ul>
            <li className="region__fio">{manager.fio}</li>
            <li> — офис: {manager.phone}</li>
            <li> — сотовый: {manager.phone_mobile}</li>
            <li> — e-mail: {manager.email}</li>
          </ul>
        </div>
        </div>

     
    )
}

export default SalesMobile