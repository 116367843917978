import React, { useState } from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql} from "gatsby";
import OfficeBtn from "../components/officeBtn";
import SalesBody from "../components/Sales/SalesBody";
import { useMediaQuery } from 'react-responsive';
import SvgIcons from "../components/svgIcons/svgIcons";

const Sales2 = () => {
  const data = useStaticQuery(graphql`
    query AllRegionsContacts {
      allStrapiRegion(sort: { order: ASC, fields: [type_region, region] }) {
        nodes {
          region
          slug
          id_region
          type_region
          manager_relation {
            fio
            email
            phone
            phone_mobile
            position
          }
        }
      }
    }
  `);
  let [isShow, setShow] = useState(false);
  const [isRegion, setRegion] = useState("");
  let [regionName, setRegionName] = useState("");

 function selectManager(manager, region) {
    setManager(manager);
    setShow(true);
    setRegionName(region);

  }
  const [manager, setManager] = useState({
    position: "Менеджер отдела продаж",
    fio: "Смирнов Вадим Дмитриевич",
    phone: "+7 (812) 640-30-30, доб. 707",
    phone_mobile: "+7-911-978-62-52",
    email: "smirnov@dendor.ru",
  });


  function closeSales () {
    setShow(false);
  }
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  



  return (
    <Layout>
      <div className="container">
        <h1 className="text-center mt-60 mb-30">ОТДЕЛ ПРОДАЖ</h1>
        <OfficeBtn />

        <div className="region">
          <div className="region__name">
            <input
              className="region__search"
              placeholder="Введите ваш регион"
              type="search"
              onChange={(event) => {
                setRegion(event.target.value);
              }}
            />
            <ul>
              {data.allStrapiRegion.nodes.map((region, index) => {
                if (
                  region.region.toLowerCase().indexOf(isRegion.toLowerCase()) >
                    -1 ||
                  !isRegion
                ) {
                  if (isDesktop) {
                    return (
                      <div key={region.id_region}>
                        {region.id_region === 86 ? (
                          <li className="dealers__type">СНГ</li>
                        ) : (
                          ""
                        )}
                        <li 
                          onMouseEnter={() =>
                            selectManager(region.manager_relation, region.region)
                          }
                          
                        >
                          {region.region}
                        </li>
                      </div>
                    );
                  }
                 
                  else {
                    return (
                      <div key={region.id_region}>
                        {region.id_region === 86 ? (
                          <li className="dealers__type">СНГ</li>
                        ) : (
                          ""
                        )}
                        <li 
                          onClick={() =>
                            selectManager(region.manager_relation, region.region)
                          }
                          
                        >
                          {region.region}
                        </li>
                      </div>
                    );
                  }
              
                }
              })}
            </ul>
          </div>
          {isShow &&   (!isDesktop && <div className="mobileCloseVacancies">
          <div className="logo">
                <a href="/">
                  <SvgIcons width="200" height="35" name="dendor" />
                </a>
              </div>
          <div className="btnCloseVacancy">    <span onClick={()=> closeSales()} className="">X</span></div>
              </div> ) }
        <SalesBody manager={manager} region = {regionName} isShow = {isShow} isDesktop={isDesktop}/>
        </div>
      </div>
    </Layout>
  );
};

export default Sales2;
