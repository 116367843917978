import React from "react";
import SalesDesktop from "./SalesDesktop";
import SalesMobile from "./SalesMobile";

const SalesBody = ({manager, isShow, region}) => {
   
  let  isDesktop = false;

 
   if (isDesktop) {

    return (
        <SalesDesktop manager={manager}/>
    )
   }
   else {
    return (
        <SalesMobile manager={manager} isShow = {isShow} region={region}/>
    )
   }
}

export default SalesBody